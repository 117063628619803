@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

:root {
  --primary:#00b4d8;
  --secondary: #90e0ef;
  --third: #caf0f8;
  --bg:#01497c;
  --white:#fff;
}

body{
  margin:0 auto;
  padding:20px;
  font-family: "Poppins";
  background: var(--bg);
  max-width: 1200px;
}

* {
  color: var(--white);
  margin: 0;
}

p {
  margin:20px 0;
  line-height: 2rem;
  text-align: justify;
}

button {
  border:0;
  padding:8px;
  border-radius: 4px;
  color:var(--white);
  background-color: var(--primary);
  cursor:pointer;
  font-size: medium;
}

h2 {
  color:var(--secondary);
  font-size: 28px;
  text-align: center;
}

header nav {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

header nav h1 {
  color: var(--primary);
  font-size: 32px;
  margin-right: auto;
  border-bottom: 3px solid var(--primary);
}

header nav a {
  text-decoration: none;
  padding: 6px;
  border-radius: 4px;
}

header nav a.active {
  background: var(--primary);

  
}

main {
  margin-top: 60px;
}

.home form {
  margin-top: 30px;
}

form input,
form label span,
form textarea {
  display: block;
}

.home form input,
.home form textarea {
  margin-bottom: 30px;
  padding: 8px;
  border-radius: 4px;
  border: 0;
  width: 300px;
  color: var(--secondary);
}


.home form label span {
  margin-bottom: 10px;
}

.resources-layout nav {
  display: flex;
  justify-content: center;
  gap:2rem;
  margin:30px 0;
}

.resources-layout nav a {
  padding:10px;
  border: 2px solid var(--secondary);
  border-radius: 1rem;
  text-decoration: none;
}
.resources-layout nav a:hover {
  background-color: var(--secondary);
  
}

.resources-layout nav a.active {
  background-color: var(--primary);
  border: 2px solid var(--primary);
}

/* news */

.new {
  background: rgba(0, 0, 0, 0.2);
  padding: 5px 20px;
  border-radius: 12px;
  margin: 20px 0;
}

.new .news_title {
  display: flex;
  justify-content: space-between;
}

.news .readmore {
  text-align: right;
}
.news a {
  text-decoration: none;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  border-bottom: 2px solid var(--secondary);
}
.news a:hover {
  background-color: var(--secondary);
}

/* Not Found */
.notfound a{
  display: inline-block;
  padding: 0.2rem 1rem;
  background-color: var(--secondary);
  border-radius: 0.5rem;
  text-decoration: none;
  color: var(--bg);
}
.notfound a:hover {
  background-color: var(--primary);
}

.pathLink {
  background-color: var(--primary);
  margin-top: 48px;
  padding:6px 20px;
  border-radius: 999px;
}

.pathLink>* {
  display: inline-block;
  margin-right: 10px;
}

.pathLink .link:after {
  content: '>';
  margin-left: 10px;
}
.pathLink .link:last-child:after {
  display: none;
}
.pathLink .link a {
  color: #ddd;
}